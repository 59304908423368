<!--历史问题处理记录时间轴-->
<template>
  <d-k-b-line v-bind="$attrs" v-on="$listeners" class="record-line" :timeline-width="130" />
</template>
<script>
  export default {
    data() {
      return {
        currentVersion: ''
      }
    },
    created() {
      console.log(this.$attrs)
    },
    methods: {
      // 点击时间轴
      onTimeLine() {
        console.log(1)
      }
    }
  }
</script>
<style lang="scss" scoped>
.record-line {
  background: #FFFFFF;
  box-shadow: 1px 1px 8px 0px rgba(179, 195, 255, 0.7);
  border-radius: 4px;
}
</style>