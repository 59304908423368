<!--新药入库处理-->
<template>
  <el-container class="new-drug-warehousing-handle-container layout-container">
    <el-header class="new-drug-warehousing-handle-header">
      <div class="title-header-container">
        <span class="title-header"></span>
        <h4 class="title-text">问题校验结果</h4>
      </div>
      <el-button type="primary" size="small" :disabled="submitDis" @click="onCheck">提交校验</el-button>
    </el-header>
    <el-main class="new-drug-warehousing-handle-main">
      <el-table
        ref="tableRef"
        :data="tableData"
        :header-cell-style="{textAlign:'center'}"
        style="width: 100%;"
        height="200"
        stripe
        border
        class="problem-table">
        <el-table-column
          align="center">
          <template slot="header" slot-scope="scope">
            <span>存在问题</span>
          </template>
          <template slot-scope="scope">
            <span v-if="scope.row.type === 3">
              <span>{{ scope.row.question1 }}</span>
              <span class="red-text">{{ scope.row.question2 }}</span>
              <span>{{ scope.row.question3 }}</span>
            </span>
            <span v-else>{{ scope.row.question }}</span>
            <span v-if="scope.row.type === 2" class="red-text">
              {{ scope.row.detailsStr }}
            </span>
            <el-button
              v-if="scope.row.type === 3"
              :disabled="!hasOpPermission"
              type="text"
              class="choose-code-btn"
              @click="onChooseCode(scope.row)">选择代码</el-button>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          width="200">
          <template slot="header" slot-scope="scope">
            <span>问题校验时间</span>
          </template>
          <template slot-scope="scope">
            <span>{{ scope.row.checkTime }}</span>
          </template>
        </el-table-column>
      </el-table>
      <section class="record-wrap">
        <div class="record-header">
          <div class="title-header-container">
            <span class="title-header"></span>
            <h4 class="title-text">历史问题处理记录</h4>
          </div>
          <el-form ref="form2" :model="searchObj" :inline="true" class="select-type-form" label-width="0px">
            <el-form-item>
              <el-select
                v-model="searchObj.type"
                class="el-select-type"
                placeholder="类型"
                @change="searchTypeHandle"
                @clear="onTypeClear">
                <el-option
                  v-for="item in typeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-date-picker
                v-if="searchObj.type === 1"
                v-model="searchObj.year"
                type="year"
                :clearable="false"
                value-format="yyyy"
                placeholder="年"
                @change="yearChangeHandle">
              </el-date-picker>
              <date-quarter
                v-else-if="searchObj.type === 2"
                ref="dataQuaterRef"
                @quarterChange="quarterChangeHandle" />
              <el-date-picker
                v-else
                v-model="searchObj.yearMonth"
                type="month"
                value-format="yyyy-MM"
                :clearable="false"
                placeholder="月份"
                @change="monthChangeHandle">
              </el-date-picker>
            </el-form-item>
          </el-form>
        </div>
        <record-line :timeline-list="timelineList" show-key="showVersion" @getCurrentItem="onTimeLine"/>
      </section>
    </el-main>
    <choose-code
      ref="chooseCodeRef"
      v-if="id"
      :org-id="orgId"
      :id="id"
      :choose-code-list="codeList"
      class="1" />
    <!-- <el-footer class="new-drug-warehousing-handle-footer"></el-footer> -->
  </el-container>
</template>

<script>
  import {
    TIME_QUERY_OBJ,
    QUATER_OPTIONS,
    MONTH_OPTIONS,
    TYPE_OPTIONS,
  } from '@/utils/dm/newDrugWarehousing/data'
  import {
    getDrugErrorHistoryApi,
    checkApi,
    getHistoryTimelineApi
  } from '@/api/dm/newDrugWarehousing/newDrugWarehousingHandle'
  import RecordLine from './components/RecordLine.vue'
  import ChooseCode from './components/ChooseCode.vue'
  export default {
    name: 'NewDrugWarehousingHandle',
    components: {
      RecordLine,
      ChooseCode
    },
    data() {
      return {
        codeList: [],
        tableData: [
          /* {
            id: 1,
            type: 1, // 说明是该类型的问题
            question: '未映射pdbox药品',
            detailsStr: null
          },
          {
            id: 2,
            type: 2,
            question: '源药品数据缺少必填项：',
            detailsStr: '厂家名称、批准文号'
          },
          {
            id: 3,
            type: 3,
            question: '代码1-n暂无映射',
            detailsStr: null
          } */
        ],
        searchObj: this.$deepClone(TIME_QUERY_OBJ),
        quaterOptions: this.$deepClone(QUATER_OPTIONS),
        monthOptions: this.$deepClone(MONTH_OPTIONS),
        typeOptions: this.$deepClone(TYPE_OPTIONS),
        timelineList: [],
        currentTimeLineObj: {}
      }
    },
    computed: {
      id() {
        return this.$route.query.id
      },
      orgId() {
        return Number(this.$route.query.orgId)
      },
      sourceType() {
        return this.$route.query.type
      },
      // 可以进行“选择代码”和“提交校验”操作
      hasOpPermission() {
        if (this.currentTimeLineObj.canUpdate) {
          if (this.sourceType !== 'view') {
            return true
          } else {
            return false
          }
        } else {
          return false
        }
      },
      submitDis() {
        if (this.sourceType !== 'view') {
          // 处理页面
          if (this.hasOpPermission) {
            return false
          } else {
            // 该版本下没有权限操作
            return true
          }
          return false
        } else {
          return true
        }
      },
    },
    async activated() {
      this.searchObj.year = this.$moment(new Date()).format('YYYY')
      await this.getVersionHandle()
      await this.fetchProblemnData()
      // if (this.sourceType !== 'view') {
      //   this.$route.meta.title = '新药入库处理'
      // } else {
      //   this.$route.meta.title = '新药入库查看'
      // }
      // this.$store.dispatch('tagsView/updateVisitedView', this.$route)
    },
    created() {
      // console.log('created')
    },
   /*  beforeRouteEnter(to, from, next) {
      next(vm => {
        if (vm.sourceType === 'view') {
          to.meta.title = '新药入库查看'
        } else {
          to.meta.title = '新药入库处理'
        }
        next()
      })
    }, */
    methods: {
      // 获取问题校验结果
      async fetchProblemnData(version = '') {
        this.tableData = []
        const obj = {
          id: this.id,
          version
        }
        let res = await getDrugErrorHistoryApi(obj)
        if (res.code === 200) {
          const data = res.data
          if (data && !data.mapped) {
            // 是否已映射pdbox 药品 true 为已映射 false为未映射
            this.tableData.push(
              {
                id: 1,
                type: 1, // 说明是该类型的问题
                question: '未映射pdbox药品',
                detailsStr: null,
                checkTime: data.checkTime
              }
            )
          }
          if (data && data.missRequired) {
            // 缺少必填项 true为有问题
            let missStr = ''
            missStr = data.missList.join(',')
            this.tableData.push({
              id: 2,
              type: 2,
              question: '源药品数据缺少必填项：',
              detailsStr: missStr,
              checkTime: data.checkTime
            })
          }
          if (data && data.codeRequired) {
            let codeStr = ''
            data.codeList && data.codeList.forEach((item, index)=> {
              if (index < data.codeList.length - 1) {
                codeStr += item.name + '-' + item.dkbName + ','
              } else {
                codeStr += item.name + '-' + item.dkbName
              }
            })
            this.tableData.push({
              id: 3,
              type: 3,
              question1: '代码',
              question2: codeStr,
              question3: '暂无映射',
              question: '代码' + codeStr + '暂无映射',
              detailsStr: null,
              checkTime: data.checkTime
            })
            this.codeList = data.codeList || []
          }
          // this.tableData = res.data || []
        }
        this.$nextTick(() => {
          this.$refs.tableRef && this.$refs.tableRef.doLayout()
        })
      },
      // 获取版本轴数据
      async getVersionHandle() {
        let obj = {
          id: this.id,
          month: this.searchObj.month,
          year: this.searchObj.year,
          quarterly: this.searchObj.quarterly
        }
        let res = await getHistoryTimelineApi(obj)
        if (res && res.code === 200) {
          this.timelineList = res.data ? res.data : []
          const len = res.data.length
          this.currentTimeLineObj = res.data[len - 1]
        } 
      },
      // 年度、月份、季度清空事件
      onTypeClear() {
        this.searchObj.year = null
        this.searchObj.month = null
        this.searchObj.quarterly = null
        this.searchObj.yearMonth = null
      },
      async searchTypeHandle(val) {
      if (val === 1) {
        // 年份
        this.searchObj.month = null
        this.searchObj.quarterly = null
        this.searchObj.yearMonth = null
        this.searchObj.year = this.$moment(new Date()).format('YYYY')
      } else if (val === 2) {
        // 季度
        this.searchObj.year = null
        this.searchObj.month = null
        this.searchObj.yearMonth = null
        this.searchObj.year = this.$moment(new Date()).format('YYYY')
        this.searchObj.quarterly = this.$moment().quarter() + ''
        this.$nextTick(() => {
          if (this.$refs.dataQuaterRef) {
            const { year, quarterly } = this.searchObj
            this.$refs.dataQuaterRef.year = year
            this.$refs.dataQuaterRef.season = quarterly
            this.$refs.dataQuaterRef.showValue = `${year}年第${quarterly}季`
          }
        })
      } else if (val === 3) {
        // 月份
        this.searchObj.year = null
        this.searchObj.quarterly = null
        this.searchObj.year = this.$moment(new Date()).format('YYYY')
        this.searchObj.month = this.$moment().format('MM') + ''
        this.searchObj.yearMonth = this.searchObj.year + '-' + this.searchObj.month
      }
      await this.getVersionHandle()
    },
      // 年份改变
      async yearChangeHandle(val) {
        this.searchObj.quarterly = ''
        this.searchObj.month = ''
        await this.getVersionHandle()
      },
      // 月份改变
      async monthChangeHandle(val) {
        if (!val) {
          return
        }
        this.searchObj.quarterly = ''
        this.searchObj.year = val.split('-')[0]
        this.searchObj.month = val.split('-')[1]
        await this.getVersionHandle()
      },
      // 季度改变
      async quarterChangeHandle(obj) {
        this.searchObj.year = obj.year
        this.searchObj.quarterly = obj.season
        this.searchObj.month = ''
        this.searchObj.yearMonth = null
        await this.getVersionHandle()
      },
      async onTimeLine(index, item) {
        this.currentTimeLineObj = this.$deepClone(item)
        await this.fetchProblemnData(item.version)
      },
      // 选择代码
      onChooseCode(row) {
        if (this.$refs.chooseCodeRef) {
          this.$refs.chooseCodeRef.dialogVisible = true
        }
      },
      // 提交校验
      async onCheck() {
        const obj = {
          id: this.id
        }
        let res = await checkApi(obj)
        if (res.code === 200) {
          if (res.data.mapped && !res.data.missRequired && !res.data.codeRequired) {
            this.$message({
              type: 'success',
              message: '校验成功'
            })
            this.$store.dispatch('tagsView/delView', this.$route)
            this.$router.push({ path: '/dm/newDrugWarehousing' })
            // await this.fetchProblemnData()
          } else {
            this.$message({
              type: 'warning',
              message: '校验失败'
            })
            // 校验失败会新增一条记录，即时间轴会新增一条数据
            await this.getVersionHandle()
            await this.fetchProblemnData()
          }
        }
      }
    }
  }
</script>
<style lang="scss" scoped>
  .title-header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    .title-header {
      width: 4px;
      height: 18px;
      background: #0073E9;
      border-radius: 2px;
    }
    .title-text {
      display: inline-block;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 18px;
      margin-left: 5px;
      vertical-align: top;
    }
  }
  .red-text {
    color: #E02626;
  }
.new-drug-warehousing-handle-container{
  .new-drug-warehousing-handle-header {
    display: flex;
    flex-wrap: wrap;
    height: auto !important;
    min-height: 55px;
    justify-content: space-between;
    align-items: center;
    
  }
  .new-drug-warehousing-handle-main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 30px;
    /* height: 300px !important; */
    .el-table {
      ::v-deep.choose-code-btn {
        margin-left: 5px;
        text-decoration: underline;
      }
    }
    .record-wrap {
      margin-top: 30px;
      .record-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
      }
      .select-type-form {
        .el-form-item {
          /* width: calc(50% - 20px); */
          width: 130px;
          .el-date-editor.el-input, .el-date-editor.el-input__inner {
            width: 100%;
            min-width: 130px; 
          }
        }
      }
    }
  }
}
</style>