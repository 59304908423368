var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    { staticClass: "new-drug-warehousing-handle-container layout-container" },
    [
      _c(
        "el-header",
        { staticClass: "new-drug-warehousing-handle-header" },
        [
          _c("div", { staticClass: "title-header-container" }, [
            _c("span", { staticClass: "title-header" }),
            _c("h4", { staticClass: "title-text" }, [_vm._v("问题校验结果")]),
          ]),
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                size: "small",
                disabled: _vm.submitDis,
              },
              on: { click: _vm.onCheck },
            },
            [_vm._v("提交校验")]
          ),
        ],
        1
      ),
      _c(
        "el-main",
        { staticClass: "new-drug-warehousing-handle-main" },
        [
          _c(
            "el-table",
            {
              ref: "tableRef",
              staticClass: "problem-table",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                "header-cell-style": { textAlign: "center" },
                height: "200",
                stripe: "",
                border: "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function (scope) {
                      return [_c("span", [_vm._v("存在问题")])]
                    },
                  },
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.type === 3
                          ? _c("span", [
                              _c("span", [_vm._v(_vm._s(scope.row.question1))]),
                              _c("span", { staticClass: "red-text" }, [
                                _vm._v(_vm._s(scope.row.question2)),
                              ]),
                              _c("span", [_vm._v(_vm._s(scope.row.question3))]),
                            ])
                          : _c("span", [_vm._v(_vm._s(scope.row.question))]),
                        scope.row.type === 2
                          ? _c("span", { staticClass: "red-text" }, [
                              _vm._v(" " + _vm._s(scope.row.detailsStr) + " "),
                            ])
                          : _vm._e(),
                        scope.row.type === 3
                          ? _c(
                              "el-button",
                              {
                                staticClass: "choose-code-btn",
                                attrs: {
                                  disabled: !_vm.hasOpPermission,
                                  type: "text",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.onChooseCode(scope.row)
                                  },
                                },
                              },
                              [_vm._v("选择代码")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "center", width: "200" },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function (scope) {
                      return [_c("span", [_vm._v("问题校验时间")])]
                    },
                  },
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.checkTime))])]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "section",
            { staticClass: "record-wrap" },
            [
              _c(
                "div",
                { staticClass: "record-header" },
                [
                  _c("div", { staticClass: "title-header-container" }, [
                    _c("span", { staticClass: "title-header" }),
                    _c("h4", { staticClass: "title-text" }, [
                      _vm._v("历史问题处理记录"),
                    ]),
                  ]),
                  _c(
                    "el-form",
                    {
                      ref: "form2",
                      staticClass: "select-type-form",
                      attrs: {
                        model: _vm.searchObj,
                        inline: true,
                        "label-width": "0px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "el-select-type",
                              attrs: { placeholder: "类型" },
                              on: {
                                change: _vm.searchTypeHandle,
                                clear: _vm.onTypeClear,
                              },
                              model: {
                                value: _vm.searchObj.type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.searchObj, "type", $$v)
                                },
                                expression: "searchObj.type",
                              },
                            },
                            _vm._l(_vm.typeOptions, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        [
                          _vm.searchObj.type === 1
                            ? _c("el-date-picker", {
                                attrs: {
                                  type: "year",
                                  clearable: false,
                                  "value-format": "yyyy",
                                  placeholder: "年",
                                },
                                on: { change: _vm.yearChangeHandle },
                                model: {
                                  value: _vm.searchObj.year,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.searchObj, "year", $$v)
                                  },
                                  expression: "searchObj.year",
                                },
                              })
                            : _vm.searchObj.type === 2
                            ? _c("date-quarter", {
                                ref: "dataQuaterRef",
                                on: { quarterChange: _vm.quarterChangeHandle },
                              })
                            : _c("el-date-picker", {
                                attrs: {
                                  type: "month",
                                  "value-format": "yyyy-MM",
                                  clearable: false,
                                  placeholder: "月份",
                                },
                                on: { change: _vm.monthChangeHandle },
                                model: {
                                  value: _vm.searchObj.yearMonth,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.searchObj, "yearMonth", $$v)
                                  },
                                  expression: "searchObj.yearMonth",
                                },
                              }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("record-line", {
                attrs: {
                  "timeline-list": _vm.timelineList,
                  "show-key": "showVersion",
                },
                on: { getCurrentItem: _vm.onTimeLine },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.id
        ? _c("choose-code", {
            ref: "chooseCodeRef",
            staticClass: "1",
            attrs: {
              "org-id": _vm.orgId,
              id: _vm.id,
              "choose-code-list": _vm.codeList,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }