<!--选择代码-->
<template>
  <el-dialog
		v-if="dialogVisible"
    title="选择代码"
    :visible.sync="dialogVisible"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    class="choose-code-dialog vertical-middle-dialog"
    width="480px"
		:append-to-body="true"
    center
    :before-close="handleClose">
    <el-form ref="form" :model="formObj" label-position="top" label-width="130px" @submit.native.prevent>
      <el-form-item label="" class="form-item">
        <el-input
          placeholder="输入关键字查询"
          prefix-icon="el-icon-search"
          v-model="formObj.searchVal"
          @input="onSearchInput" />
      </el-form-item>
    </el-form>
    <section class="code-item-section-wrap">
      <!-- <div v-for="(item, index) in codeDataList" :key="index" class="code-item-wrap">
        <header class="code-item-title">{{ item.name }}</header>
        <el-collapse accordion v-for="child in item.children" :key="child.code" class="code-collapse">
          <el-collapse-item>
            <template slot="title">
              {{ child.name }}
            </template>
            <el-radio-group v-for="kid in child.children" v-model="selectList">
              <el-radio :label="kid.id">{{ kid.name }}</el-radio>
            </el-radio-group>
          </el-collapse-item>
        </el-collapse>
      </div> -->
      <div v-for="(item, index) in codeDataList" :key="index" class="code-item-wrap">
        <header class="code-item-title">{{ item.name + '-' + item.dkbName }}</header>
        <!-- <el-collapse accordion v-for="child in item.children" :key="child.code" class="code-collapse">
          <el-collapse-item>
            <template slot="title">
              {{ child.name }}
            </template>
            <el-radio-group v-for="kid in child.children" v-model="selectList">
              <el-radio :label="kid.id">{{ kid.name }}</el-radio>
            </el-radio-group>
          </el-collapse-item>
        </el-collapse> -->
        <el-radio-group
          v-for="kid in item.children"
          v-model="item.selectList"
          class="code-radio-group"
          @change="(val) => onCodeChange(val, kid, item)">
          <el-radio :label="kid.code">{{ kid.name }}</el-radio>
        </el-radio-group>
      </div>
    </section>
    <span slot="footer" class="dialog-footer">
			<el-button type="primary" @click="confirmHandle">确定</el-button>
			<el-button @click="dialogVisible = false">取消</el-button>
    </span>
  </el-dialog>
</template>
<script>
  import {
    getCodeInfoApi,
    updateCodeApi
  } from '@/api/dm/newDrugWarehousing/newDrugWarehousingHandle'
  export default {
    props: {
      orgId: {
        type: Number,
        required: true
      },
      id: {
        type: String,
        required: true
      },
      chooseCodeList: {
        type: Array,
        required: true
      }
    },
    data() {
      return {
        dialogVisible: false,
        formObj: {
          searchVal: ''
        },
        selectList: [],
        codeDataList: [],
        allCodeDataList: [],
        codeList: [
          {
            id: 1,
            name: '散剂',
            children: [
              {
                id: 11,
                name: '散剂',
                children: [
                  {
                    id: 111,
                    name: '眼用散剂'
                  },
                  {
                    id: 112,
                    name: '局部用散剂'
                  }
                ]
              }
            ]
          },
          {
            id: 2,
            name: '清热剂',
            children: [
              {
                id: 21,
                name: '内科用药',
                children: [
                  {
                    id: 211,
                    name: '清热剂'
                  }
                ]
              },
              {
                id: 22,
                name: '儿童类',
                children: [
                  {
                    id: 221,
                    name: '儿童清热剂'
                  }
                ]
              },
            ]
          },
          {
            id: 3,
            name: '清热剂',
            children: [
              {
                id: 31,
                name: '内科用药',
                children: [
                  {
                    id: 311,
                    name: '清热剂'
                  }
                ]
              },
              {
                id: 32,
                name: '儿童类',
                children: [
                  {
                    id: 321,
                    name: '儿童清热剂'
                  }
                ]
              },
            ]
          }
        ]
      }
    },
    watch: {
      chooseCodeList: {
        async handler(newVal, oldVal) {
          // if (newVal.length > 0 && oldVal.length > 0)
            await this.fetchData()
        },
        deep: true
      },
      dialogVisible(newVal, oldVal) {
      }
    },
    async created() {
      // await this.fetchData()
    },
    methods: {
      async fetchData(searchValue = '') {
        this.codeDataList = []
        for(let item of this.chooseCodeList) {
          const obj = {
            conceptId: item.conceptId,
            domainCode: item.domainCode,
            orgId: this.orgId,
            searchValue
          }
          let res = await getCodeInfoApi(obj)
          if (res.code === 200) {
            this.codeDataList.push({
              conceptId: item.conceptId,
              name: item.name,
              dkbName: item.dkbName,
              fieldName: item.fieldName,
              children: res.data,
              selectList: '',
              selectItem: {}
            })
          }
          this.allCodeDataList = this.$deepClone(this.codeDataList)
        }
      },
      onCodeChange(val, item, parent) {
        parent.selectItem = this.$deepClone(item)
        this.allCodeDataList.forEach(code => {
          if (code.conceptId === parent.conceptId) {
            code.selectList = val
            code.selectItem = this.$deepClone(item)
          }
        })
      },
      async confirmHandle() {
        let obj = {
          addDrugId: '', // id号
          updateCode: '',
          updateValue: '', // 更新的值 当更新内容为文本时
          updateField: '', // 更新字段
        }
        let arr = []
        for(let item of this.allCodeDataList) {
          arr.push({
            addDrugId: this.id,
            updateCode: item.selectItem.code,
            updateValue: item.selectItem.name,
            updateField: item.fieldName
          })
        }
        let res = await updateCodeApi(arr)
        if (res.code === 200) {
          this.$message({
            type: 'success',
            message: '选择代码成功'
          })
          this.dialogVisible = false
        }
        // console.log(this.codeDataList)
      },
      // 查询
      async onSearchInput(val) {
        let codeDataList = this.$deepClone(this.allCodeDataList)
        for (let i = 0; i < this.allCodeDataList.length; i++) {
          let child = this.allCodeDataList[i]
          let children = child.children.filter(kid => {
            return kid.name.includes(val)
          })
          codeDataList[i].children = children
        }
        let list = codeDataList.filter(item => item.children.length > 0)
        this.codeDataList = this.$deepClone(list)
      },
      handleClose() {
        this.dialogVisible = false
      }
    }
  }
</script>
<style lang="scss" scoped>
.choose-code-dialog {
  ::v-deep.el-dialog__body {
    display: flex;
    flex-direction: column;
  }
  .code-item-section-wrap {
    flex: 1;
    overflow: auto;
    border: 1px solid #C8D6FA;
    padding: 15px;
    .code-item-wrap {
      border: 1px solid #C3CDED;
      border-radius: 4px;
      margin-bottom: 15px;
      padding-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
      .code-item-title {
        background-color: #F0F2FA;
        text-align: center;
        padding: 5px 0;
      }
      .code-collapse {
        padding-left: 20px;
        .el-radio-group {
          display: block;
          margin-bottom: 5px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      .code-radio-group {
        display: block;
        margin-bottom: 5px;
        padding: 10px 0 0px 10px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  
}
</style>