/**新药入库维护 */
export const QUERY_DATA_OBJ = {
  isHandle: -1, // 是否已处理
  pageNum: 1,
  pageSize: 10,
  timeList: [],
  beginTime: '',
  endTime: '',
  isAsc: '', // 排序的方向 desc/asc
  orderByColumn: '', // 排序列
  orgId: '',
  orgName: '',
  searchVal: '',
  webStatus: '', // 处理状态（3未处理 4已处理）
}
/**新药入库处理 */
export const QUATER_OPTIONS = [
  {
    value: 1,
    label: '第一季度'
  },
  {
    value: 2,
    label: '第二季度'
  },
  {
    value: 3,
    label: '第三季度'
  },
  {
    value: 4,
    label: '第四季度'
  }
]
export const TYPE_OPTIONS = [
  {
    value: 1,
    label: '年度'
  },
  {
    value: 2,
    label: '季度'
  },
  {
    value: 3,
    label: '月份'
  }
]
export const MONTH_OPTIONS = [
  {
    value: 1,
    label: '1月'
  },
  {
    value: 2,
    label: '2月'
  },
  {
    value: 3,
    label: '3月'
  },
  {
    value: 4,
    label: '4月'
  },
  {
    value: 5,
    label: '5月'
  },
  {
    value: 6,
    label: '6月'
  },
  {
    value: 7,
    label: '7月'
  },
  {
    value: 8,
    label: '8月'
  },
  {
    value: 9,
    label: '9月'
  },
  {
    value: 10,
    label: '10月'
  },
  {
    value: 11,
    label: '11月'
  },
  {
    value: 12,
    label: '12月'
  }
]
export const TIME_QUERY_OBJ = {
  id: '',
  type: 1,
  month: null,
  quarterly: null,
  year: null,
  yearMonth: null
}
