/**新药入库处理 */
import request from '@/utils/request'

// 获取药品错误信息
export function getDrugErrorInfoApi(data) {
  return request({
    url: '/dm/drug/deal/error/' + data.id,
    method: 'get',
  })
}

// 查看药品变更历史
export function getDrugErrorHistoryApi(data) {
  return request({
    url: '/dm/drug/deal/history/info',
    method: 'get',
    params: data
  })
}
// 查看药品变更历史时间轴
export function getHistoryTimelineApi(data) {
  return request({
    url: '/dm/drug/deal/history',
    method: 'get',
    params: data
  })
}
// 校验药品错误信息
export function checkApi(data) {
  return request({
    url: '/dm/drug/deal/check/' + data.id,
    method: 'get'
  })
}
// 获取机构字典信息（选择代码弹窗）
export function getCodeInfoApi(data) {
  return request({
    url: '/term/term/match/org/dict',
    method: 'get',
    params: data
  })
}

// 对药品信息进行批量更新（选择代码弹窗）/dm/drug/deal/update/batch
export function updateCodeApi(data) {
  return request({
    url: '/dm/drug/deal/update/batch',
    method: 'put',
    data
  })
}
